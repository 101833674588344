import type { CustomPageReadType } from '@readme/api/src/mappings/custompage/types';
import type { HubResponseProps } from '@readme/iso';

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { InitializeSuperHubDocument, ConnectSuperHubDocumentToApi, useSuperHubStore } from '@core/store';
import { htmlBlock } from '@core/utils/magicBlocks';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import RDMD from '@ui/RDMD';

import ConnectRoute from '../ConnectRoute';
import RedirectToProjectFirstPage from '../RedirectToProjectFirstPage';

import styles from './index.module.scss';

/**
 * Shape of route specific data for custom pages.
 */
export interface CustomPageRouteProps {
  apiDefinitions?: never;
  document: CustomPageReadType['data'];
}

function Content({ rdmdOpts }: HubResponseProps<CustomPageRouteProps>) {
  const bem = useClassy(styles, 'SuperHubCustomPage');
  const customPage = useSuperHubStore(s => s.document.getCustomPageData());

  const content = useMemo(
    () =>
      customPage?.content.type === 'html' && customPage.content.body
        ? htmlBlock(customPage.content.body)
        : customPage?.content.body,
    [customPage?.content.body, customPage?.content.type],
  );

  return (
    <div className={bem('&', 'rm-customPage', customPage?.appearance.fullscreen && '_fullscreen')}>
      <div className={bem('-content')}>
        <header className={bem('-content-header')}>
          <h1>{customPage?.title}</h1>
        </header>
        <RDMD key={customPage?.slug} opts={{ ...rdmdOpts, mdx: true }}>
          {content}
        </RDMD>
        <div className="ModalWrapper" id="tutorialmodal-root" />
      </div>
    </div>
  );
}

/**
 * Renders custom pages in "view" only mode.
 */
export default function SuperHubCustomPage(props: HubResponseProps<CustomPageRouteProps>) {
  const { document: initialDocument } = props;
  const { slug } = useParams<SuperHubRouteParams>();

  // When route is /page with no slug, redirect to the project's first page.
  if (!slug) {
    return <RedirectToProjectFirstPage />;
  }

  return (
    <InitializeSuperHubDocument document={initialDocument}>
      <ConnectSuperHubDocumentToApi slug={slug}>
        <ConnectRoute next={Content} props={props} />
      </ConnectSuperHubDocumentToApi>
    </InitializeSuperHubDocument>
  );
}
