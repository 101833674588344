import type { ReadCustomBlockGitCollectionType } from '@readme/api/src/mappings/customblock/types';
import type { ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';
import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { $TSFixMe, HubResponseProps } from '@readme/iso';

import { findOperation, sortHeaders } from '@readme/server-shared/metrics-oas'; // eslint-disable-line readme-internal/no-restricted-imports
import { EXPLORER_ENABLED, METRICS_ENABLED } from 'oas/extensions';
import { Operation } from 'oas/operation';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import type { ProjectContextValue, UserContextValue } from '@core/context';
import { APIBaseUrlContext, ProjectContext, UserContext } from '@core/context';
import useReadmeApi from '@core/hooks/deprecated/useReadmeApi';
import useSentry from '@core/hooks/useSentry';
import useUserPermissions from '@core/hooks/useUserPermissions';
import {
  ConnectSuperHubDocumentToApi,
  ConnectSuperHubSidebarToApi,
  InitializeReferenceStore,
  InitializeSuperHubDocument,
  useProjectStore,
  useReferenceStore,
  useSuperHubStore,
} from '@core/store';
import ScrollTop from '@core/utils/ScrollTop';

import classes from '@routes/Reference//style.module.scss';
import AuthContainer from '@routes/Reference/components/AuthContainer';
import ErrorBoundary from '@routes/Reference/components/ErrorBoundary';
import GraphContainer from '@routes/Reference/components/GraphContainer';
import Playground, { PlaygroundSection } from '@routes/Reference/components/Playground';
import RequestContainer from '@routes/Reference/components/RequestContainer';
import ServerContainer from '@routes/Reference/components/ServerContainer';
import ShareLogContainer from '@routes/Reference/components/ShareLogContainer';
import TableContainer from '@routes/Reference/components/TableContainer';
import HARContext, { EphemeralHARContext, MockHarContext } from '@routes/Reference/context/HARContext';
import useAuthInputs from '@routes/Reference/hooks/useAuthInputs';
import useOas from '@routes/Reference/hooks/useOas';
import UserAvatar from '@routes/Reference/Realtime/components/UserAvatar';
import '@routes/Reference/style.scss';
import { type SuperHubRouteParams } from '@routes/SuperHub/types';

import Callbacks from '@ui/API/Callbacks';
import Header from '@ui/API/Header';
import Response from '@ui/API/Response';
import ResponseSchemaPicker from '@ui/API/ResponseSchemaPicker';
import { createSchema } from '@ui/API/Schema';
import SectionHeader from '@ui/API/SectionHeader';
import Footer from '@ui/DocFooter/SuperhubDocFooter';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import LanguagePicker from '@ui/LanguagePicker';
import QuickNav from '@ui/QuickNav';
import transformSidebarDataForQuickNav from '@ui/QuickNav/inputDataTransformers/transformSidebarDataForQuickNav';
import RDMD, { TOC } from '@ui/RDMD';
import Sidebar from '@ui/Sidebar';

import ConnectRoute from '../ConnectRoute';
import RedirectToEmptyParentChild from '../RedirectToEmptyParentChild';
import RedirectToSidebarFirstPage from '../RedirectToSidebarFirstPage';

import EmptyReference from './Empty';
import { normalizeGitDocToMongo } from './util';

/**
 * Shape of route specific data for API reference pages.
 */
export interface ReferenceRouteProps {
  apiDefinitions?: never;
  customBlocks: ReadCustomBlockGitCollectionType['data'];
  document: ReadReferenceType['data'];
  maskErrorMessages: boolean;
  /**
   * @todo figure out if we need support for this.
   * @link https://linear.app/readme-io/issue/RM-10786/figure-out-what-oaspublicurl-is-and-how-to-populate-it-for-superhub
   */
  oasPublicUrl: string;
  oauth: boolean;
  reusableContent: Record<string, string>;
  sidebar: GitSidebarCategory[];
}

/**
 * @todo Update this to be inferred from the document once the API supports Realtime pages.
 * doc.pageType === 'RealtimePage' not implemented on git backed document
 */
const isRealtimePage = false;

/**
 * This is a fork of `@routes/Reference/index` component that is used to render
 * `/reference/:slug` routes when `project.flags.superHub` is enabled.
 *
 * Because SuperHub uses APIv2 data, and the shape of that data is different
 * from internal Hub api data, we need to fork this component to handle the
 * differences.
 */
function Content({
  document: initialDocument,
  maskErrorMessages = true,
  oasPublicUrl,
  oauth = false,
  rdmdOpts,
  reusableContent,
}: HubResponseProps<ReferenceRouteProps>) {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { isAdminUser, isLoggedIn } = useUserPermissions();
  const apiBaseUrl = useContext(APIBaseUrlContext);
  const { user } = useContext(UserContext) as unknown as UserContextValue;
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { clearEphemeralHAR, ephemeralHAR, setEphemeralHAR } = useContext(EphemeralHARContext);
  const { clearMockHars, shouldUseMockHars } = useContext(MockHarContext);

  const [selectedHar, setSelectedHar, servers] = useReferenceStore(s => [
    s.selectedHar,
    s.updateSelectedHar,
    s.form.schemaEditor.data.server,
  ]);
  const [
    enableApiSdkSnippets,
    enableDefaults,
    enableJsonEditor,
    enableRequestHistory,
    expandResponseExamples,
    expandResponseSchemas,
    projectSubdomain,
    hideTOC,
  ] = useProjectStore(s => [
    s.data.reference.api_sdk_snippets === 'enabled',
    s.data.reference.defaults === 'always_use',
    s.data.reference.json_editor === 'enabled',
    s.data.reference.request_history === 'enabled',
    s.data.reference.response_examples === 'expanded',
    s.data.reference.response_schemas === 'expanded',
    s.data.subdomain,
    s.data.appearance.table_of_contents === 'disabled',
  ]);

  const [showLogs, setShowLogs] = useState(false);

  /**
   * @todo we should add this to the APIv2 project mapper so we can read this
   * off the project store instead of ProjectContext
   */
  const metricsThumbsEnabled = project.metrics.thumbsEnabled;

  const [isLoading, isLoadingSidebar, document, customBlocks, sidebar] = useSuperHubStore(s => [
    s.document.isLoading && s.document.data !== initialDocument,
    s.sidebar.isLoading,
    s.document.getReferencePageData(),
    s.document.getCustomBlocksDictionary(),
    s.sidebar.data,
  ]);

  const oasDefinition = document ? document.api.schema : null;
  const legacyDoc = useMemo(() => normalizeGitDocToMongo(document ?? undefined), [document]);

  const { oas, operation, dereferencingError, isLoading: isOasDereferencing } = useOas(oasDefinition, legacyDoc);

  const { inputRef, onAuthError } = useAuthInputs();

  useEffect(() => {
    // Reset the HAR file in state when a new operation is selected.
    clearMockHars();
    clearEphemeralHAR();
  }, [operation]); // eslint-disable-line react-hooks/exhaustive-deps

  const nextRdmdOpts = useMemo(() => {
    /**
     * @todo reusableContent value is from the SSR prop, but should also
     * get loaded from the useRoute hook and updated here when the route changes
     * once the API is ready.
     */
    return {
      ...rdmdOpts,
      components: customBlocks,
      copyButtons: true,
      reusableContent,
    };

    // Disabling exhustive-deps because some deps are mocked
  }, [customBlocks, rdmdOpts, reusableContent]);

  const isEndpoint = document?.type === 'endpoint';

  const isWebhook = (document as $TSFixMe)?.type === 'webhook';

  /**
   * A normalized URL that we'll pass through to our Metrics service
   * This URL allows us to filter API logs for a specific endpoint/resource
   *
   * Returns a value similar to https://{region}.server.com/path/${resource}
   * In metrics, we'll use this value to query our database with wildcards via
   * fuzzy matching in place of each {curly brace} value.
   *
   * * @todo  Since `GraphContainer`, `TableContainer` and `APIHeader` all use this same data maybe it should be in a context?
   */
  const metricsFilterUrl = useMemo(() => {
    if (operation === null || !isEndpoint) {
      return null;
    }

    return `${oas.url(servers?.selected, {})}${operation.path}`;
    // We don't want to do a reference comparison for oas, we only care if the `id` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEndpoint, oas?.api?._id, servers, operation]);

  const preparedQuickNavData = useMemo(() => {
    try {
      return transformSidebarDataForQuickNav(sidebar);
    } catch {
      return null;
    }
  }, [sidebar]);

  const header = useMemo(
    () => (
      <div className={classes['Main-QuickNav-container']}>
        <QuickNav destinations={preparedQuickNavData} modalTarget={'#QuickNav-modal-root'} />
      </div>
    ),
    [preparedQuickNavData],
  );

  // Are try-it-now requests enabled in the explorer via OAS
  const requestsEnabled = useMemo(
    () => !!oas?.getExtension(EXPLORER_ENABLED, operation) || false,
    // We don't want to do a reference comparison for oas, we only care if the `id` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oas.api?._id, operation],
  );

  const { initRequest, response: usageResponse } = useReadmeApi(apiBaseUrl);

  useEffect(() => {
    // If they've disabled try-it-now in the explorer, but they've got the module enabled
    // We should check to see if they're actually using it
    if (!requestsEnabled) initRequest({ path: 'api/logs/usage' });
  }, [initRequest, apiBaseUrl, requestsEnabled]);

  useEffect(() => {
    // We should ever show logs on an operation if Metrics is explicitly or implicitly enabled on
    // it and if interactivity is enabled or it's clear that Metrics is in use from usage reports.
    if (!oas.getExtension(METRICS_ENABLED, operation)) {
      setShowLogs(false);
      return;
    }

    setShowLogs(requestsEnabled || parseInt(usageResponse?.sdk?.thirtyDay, 10) > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oas?.api?._id, operation, requestsEnabled, usageResponse]);

  // Is My Requests page enabled (Dev Dash in Hub)
  const myRequestsEnabled = useMemo(() => {
    return !!sidebar?.[0]?.pages?.find(({ slug }) => slug === 'my-requests') || false;
  }, [sidebar]);

  // Condition to show the Share Log UI; our API routes will still check these permissions before allowing the share to be created/modified
  const showLogShareOptions = useMemo(() => {
    // If Metrics is explicitly disabled on this operation then we shouldn't ever show the log share
    // option.
    if (!oas.getExtension(METRICS_ENABLED, operation)) {
      return false;
    }

    const isLogCreator = user?.email && user.email === selectedHar?.group?.email;
    return selectedHar && !selectedHar?.isMock && (isAdminUser || isLogCreator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminUser, selectedHar, oas?.api?._id, operation, user?.email]);

  const showAuthUIOnSharedLog = useMemo(() => {
    const isLogCreator = user?.email && user.email === selectedHar?.group?.email;
    const isShareLink = new URLSearchParams(location.search).get('shareId');
    return !isShareLink || isLogCreator || isAdminUser;
  }, [isAdminUser, location.search, selectedHar?.group?.email, user?.email]);

  // Custom icon render for My Requests page in sidebar list
  const customIconRender = useCallback(
    (pageType, title) => {
      const isYourRequests = pageType === 'RealtimePage' && title === 'My Requests';

      // "My Requests" page in sidebar has special icon handling
      return isLoggedIn && isYourRequests ? <UserAvatar /> : null;
    },
    [isLoggedIn],
  );

  const Params = useMemo(() => {
    // This needs to be memoized! If it isn't we'll recreate the Params component everytime a state change happens,
    // which will cause the user to lose focus on their input.
    return createSchema(oas, operation);
    // We don't want to do a reference comparison for oas, we only care if the `id` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oas?.api?._id, operation]);

  const handleSelectLog = useCallback(
    ({ id, response }) => {
      // Always clear the har shown in the playground when a log is de-selected
      if (id == null) {
        setEphemeralHAR(false);
      }

      if (id == null || response?.log == null) {
        setSelectedHar(null);
        return;
      } else if (isOasDereferencing) {
        return;
      }

      const { log, sharedMetric } = response;
      const { logOperation } = findOperation(log, [oas]) as $TSFixMe;

      // If an operation is not found for this log so we silently ignore it
      if (logOperation == null) {
        return;
      }

      const op = new Operation(oas?.api, logOperation.url.path, logOperation.url.method, logOperation.operation);
      // This function ensures the request's headers only includes those that are those present in the OAS
      const nextLog = sortHeaders(log, op);
      const { createdAt, group } = log;

      setSelectedHar({ id, isMock: Boolean(nextLog.isMock), createdAt, group, sharedMetric, ...nextLog.request });
    },
    // We don't want to do a reference comparison for oas, we only care if dereferencing has finished or the
    // internal `id` has changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOasDereferencing, oas?.api?._id],
  );

  const onError = useSentry('reference');

  /**
   * Error Boundry
   */
  if (dereferencingError) {
    // We're passing in `error.message` into the error boundary because it expects a child node to be present. It's fine
    // though as since we're also passing an `error` prop we'll default to our standard error handling instead of
    // rendering out that error message.
    return (
      <ErrorBoundary
        appContext="explorer"
        error={dereferencingError}
        maskErrorMessages={maskErrorMessages}
        onError={onError}
      >
        {(dereferencingError as $TSFixMe).message}
      </ErrorBoundary>
    );
  } else if (operation === null && isEndpoint) {
    if (isLoading) {
      // Don't return an error before the route has actually loaded!
      return <main className="loading" id="Reference" />;
    }

    return (
      <ErrorBoundary
        appContext="explorer"
        error={new Error('Operation unable to be located within the supplied OAS.')}
        maskErrorMessages={maskErrorMessages}
        onError={onError}
      >
        We’re sorry, we weren’t able to render the docs for this API operation.
      </ErrorBoundary>
    );
  }

  // When no API definitions yet exist, force the user into our creator flow.
  if (!isLoading && !isLoadingSidebar && !document) {
    return <EmptyReference />;
  }

  return (
    <ErrorBoundary appContext="explorer" maskErrorMessages={maskErrorMessages} onError={onError}>
      <InitializeReferenceStore
        apiDefinition={oas}
        maxLanguages={isRealtimePage ? 7 : 5}
        operation={operation}
        simpleMode={enableApiSdkSnippets}
      >
        <main
          className={`rm-ReferenceMain rm-Container rm-Container_flex ${isEndpoint ? '' : 'rm-basic-page'} ${
            isLoading ? 'loading' : ''
          }`}
          id="Explorer"
        >
          <Sidebar
            activeDoc={document?.slug}
            categories={sidebar}
            customIconRender={customIconRender}
            header={preparedQuickNavData ? header : undefined}
            id="reference-sidebar"
            pathRoot="reference"
          />
          <Switch>
            {/**
             * @todo Integrate git-backed realtime pages
             * <Route path={`${path}/intro/:slug(getting-started|authentication|my-requests)`}>…</Route>
             */}

            <Route path={`${path}/:slug?`}>
              <article className="rm-Article" id="content">
                {!!legacyDoc && (
                  <Header
                    doc={legacyDoc}
                    isWebhook={isWebhook}
                    oas={oas}
                    operation={operation}
                    servers={servers}
                    sidebar={sidebar}
                  />
                )}
                {/**
                 * @todo Tutorials are not yet supported in git backed projects
                 * {!!doc && !!doc.tutorials && doc.tutorials.length > 0 && (
                 *  <RecipeContainer tutorials={doc.tutorials} />
                 * )}
                 */}
                {!!enableRequestHistory && !!isEndpoint && !!showLogs && (
                  <ErrorBoundary appContext="explorer" maskErrorMessages={maskErrorMessages} onError={onError}>
                    <div className="Reference-section">
                      <SectionHeader
                        heading={
                          <Flex align="center" gap="xs" justify="start">
                            {!isLoggedIn ? (
                              <>
                                <Icon aria-label="Key" name="key" />
                                Log in to see full request history
                              </>
                            ) : (
                              'Recent Requests'
                            )}
                          </Flex>
                        }
                      />

                      <TableContainer
                        ephemeralHAR={ephemeralHAR as $TSFixMe}
                        footer={
                          !shouldUseMockHars && (
                            <GraphContainer
                              ephemeralHAR={ephemeralHAR as $TSFixMe}
                              isLoggedIn={isLoggedIn}
                              method={operation.method}
                              myRequestsEnabled={myRequestsEnabled}
                              url={metricsFilterUrl || ''}
                            />
                          )
                        }
                        method={operation.method}
                        onSelectLog={handleSelectLog}
                        selectedHarId={selectedHar?.id as $TSFixMe}
                        url={metricsFilterUrl || ''}
                      />
                    </div>
                  </ErrorBoundary>
                )}

                {!!document?.content.body && (
                  <RDMD key={document.slug} body={document.content.body} className="content-body" opts={nextRdmdOpts} />
                )}

                {(!!isEndpoint || !!isWebhook) && (
                  <>
                    <Params
                      alwaysUseDefaults={enableDefaults}
                      globalDefaults={user?.parameters}
                      isWebhook={isWebhook}
                      oas={oas}
                      // onSubmit={() => {}} // @todo This should be wired up into the same executor as clicking the "Try It" button.
                      operation={operation}
                    />
                    <ResponseSchemaPicker
                      defaultExpandResponseSchema={expandResponseSchemas}
                      oas={oas}
                      operation={operation}
                    />
                    <div className="ModalWrapper" id="response-schema-modal-target" />
                  </>
                )}

                {!!operation?.hasCallbacks() && (
                  <>
                    <Callbacks oas={oas} operation={operation} />
                    <div className="ModalWrapper" id="callback-response-schema-modal-target" />
                  </>
                )}

                {!(isLoading || isOasDereferencing) && (
                  <div className={classes['Footer-desktop']}>
                    <Footer
                      apiBaseUrl={apiBaseUrl}
                      document={document ?? undefined}
                      metricsThumbsEnabled={metricsThumbsEnabled}
                      projectSubdomain={projectSubdomain}
                      sidebar={sidebar}
                      userEmail={user.email}
                    />
                  </div>
                )}
              </article>
              {(!!isEndpoint || !!isWebhook) && (
                <Playground id="ReferencePlayground">
                  {!!isEndpoint && (
                    <>
                      <PlaygroundSection>
                        {!!showLogShareOptions && (
                          <ShareLogContainer
                            createdAt={selectedHar?.createdAt}
                            id={selectedHar?.id}
                            isEnabled={selectedHar?.sharedMetric?.isEnabled}
                            shareId={selectedHar?.sharedMetric?.shareId}
                          />
                        )}
                      </PlaygroundSection>
                      <PlaygroundSection heading="Language">
                        <LanguagePicker />
                      </PlaygroundSection>
                      <PlaygroundSection>
                        {!!showAuthUIOnSharedLog && (
                          <AuthContainer
                            apiDefinition={oas}
                            customLoginEnabled={!!project.oauth_url}
                            inputRef={inputRef}
                            oauth={oauth}
                            operation={operation}
                            setSelectedHar={setSelectedHar as $TSFixMe}
                          />
                        )}
                      </PlaygroundSection>
                      {/*
                          Only show the servers component if one of these conditions are true:
                          - there is more than one server in the servers[] array
                          - the server URL has a variable (so needs editing)
                          @todo this should probably be refactored into Oas.hasEditableServer() or something
                        */}
                      {((oas?.api?.servers || [{ url: 'https://example.com' }]).length > 1 ||
                        oas.splitUrl(servers?.selected).filter(({ type }) => type === 'variable').length > 0) &&
                        !!showAuthUIOnSharedLog && (
                          <PlaygroundSection heading="URL">
                            <ServerContainer oas={oas} operation={operation} />
                          </PlaygroundSection>
                        )}
                    </>
                  )}
                  <PlaygroundSection sticky>
                    <RequestContainer
                      allowApiExplorerJsonEditor={enableJsonEditor}
                      apiDefinition={oas}
                      har={selectedHar as $TSFixMe}
                      isWebhook={isWebhook}
                      onError={onAuthError}
                      operation={operation}
                      requestsEnabled={requestsEnabled}
                      setResponseHAR={setEphemeralHAR}
                      url={oasPublicUrl}
                    />
                    {!!isEndpoint && (
                      <Response
                        apiDefinition={oas}
                        defaultExpandResponseExample={expandResponseExamples}
                        har={selectedHar || ephemeralHAR || null}
                        onExampleRemove={clearEphemeralHAR}
                        operation={operation}
                        requestsEnabled={requestsEnabled}
                        setSelectedHar={setSelectedHar}
                      />
                    )}
                  </PlaygroundSection>
                </Playground>
              )}

              {!(isLoading || isOasDereferencing) && (
                <div className={classes['Footer-mobile']}>
                  <Footer
                    apiBaseUrl={apiBaseUrl}
                    document={document ?? undefined}
                    metricsThumbsEnabled={metricsThumbsEnabled}
                    projectSubdomain={projectSubdomain}
                    sidebar={sidebar}
                    userEmail={user.email}
                  />
                </div>
              )}
              {!hideTOC && document?.type === 'basic' && (
                <section className="content-toc grid-25">
                  <TOC body={document?.content.body} opts={{ mdx: true }} />
                </section>
              )}
            </Route>
          </Switch>

          {/**
           * Response headers modal root lives outside <Response/> component to escape z-index trapping from
           * a position:sticky; wrapper in the parent <PlaygroundSection/> component
           */}
          <div className="ModalWrapper" id="response-headers-modal-root" />

          <div className="ModalWrapper" id="tutorialmodal-root"></div>
          <div className="ModalWrapper QuickNav-modal QuickNav-modal-desktop" id="QuickNav-modal-root" />
          <div className="ModalWrapper QuickNav-modal QuickNav-modal-mobile" id="QuickNav-mobile-modal-root" />
        </main>
      </InitializeReferenceStore>
    </ErrorBoundary>
  );
}

export default function SuperHubReference({ ...props }: HubResponseProps<ReferenceRouteProps>) {
  const { document: initialDocument, customBlocks: initialCustomBlocks } = props;
  const { slug } = useParams<SuperHubRouteParams>();

  return (
    <ConnectSuperHubSidebarToApi>
      <RedirectToSidebarFirstPage>
        <RedirectToEmptyParentChild>
          <InitializeSuperHubDocument customBlocks={initialCustomBlocks} document={initialDocument}>
            <ConnectSuperHubDocumentToApi slug={slug}>
              <HARContext>
                <ScrollTop smooth />
                <ConnectRoute next={Content} props={props} />
              </HARContext>
            </ConnectSuperHubDocumentToApi>
          </InitializeSuperHubDocument>
        </RedirectToEmptyParentChild>
      </RedirectToSidebarFirstPage>
    </ConnectSuperHubSidebarToApi>
  );
}
