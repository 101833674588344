import type { TypeIconClasses } from '.';
import type { SuperHubRouteParams } from '../types';
import type { ReadChangelogType } from '@readme/api/src/mappings/changelog/types';

import React from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import {
  InitializeSuperHubDocument,
  ConnectSuperHubDocumentToApi,
  useProjectStore,
  useSuperHubStore,
} from '@core/store';

import Button from '@ui/Button';
import DateLine from '@ui/DateLine';
import Flex from '@ui/Flex';
import RDMD from '@ui/RDMD';
import Spinner from '@ui/Spinner';

import classes from './style.module.scss';

interface LocationState {
  fromChangelogList: boolean;
}

function Back({ children, className, ...props }) {
  const { state } = useLocation<LocationState | undefined>();
  const history = useHistory();

  return state?.fromChangelogList ? (
    <Button {...props} className={`${className} ${classes.ChangelogPost_back}`} onClick={() => history.goBack()}>
      {children}
    </Button>
  ) : (
    <Link {...props} className={`${className} ${classes.ChangelogPost_back}`} to="/changelog">
      {children}
    </Link>
  );
}

interface ChangelogPostProps {
  changelog?: ReadChangelogType['data'];
  className?: string;
  initialChangelog?: ReadChangelogType['data'];
  isList?: boolean;
  typeIconClasses: TypeIconClasses;
}

function Content({
  changelog: changelogProp,
  className,
  initialChangelog,
  isList = false,
  typeIconClasses,
}: ChangelogPostProps) {
  const [changelog, isLoading] = useSuperHubStore(s => [
    changelogProp ?? s.document.getChangelogData(),
    s.document.isLoading && (s.document.data ? s.document.data !== initialChangelog : true),
  ]);
  const [showAuthor, showExactDate] = useProjectStore(s => [
    !!s.data.appearance.changelog.show_author,
    !!s.data.appearance.changelog.show_exact_date,
  ]);

  const { type = 'none', title, author, created_at: createdAt = '', slug } = changelog || {};
  const changelogIcon = showExactDate ? '' : 'icon-clock';
  const changelogFormat = showExactDate ? { fnsFormat: 'PPP' } : {};

  return (
    <main className="rm-Changelog" id="content">
      <div className="rm-Container rm-ContainerNarrow">
        <article
          className={`${className} ${classes.ChangelogPage} ${classes.ChangelogPage_list} rm-Changelog-post`}
          id={title?.replace(/\s+/g, '-').toLowerCase()}
        >
          {!isList ? (
            <Flex align="center" className={classes.ChangelogPage_back} justify="end">
              <Back className="Button Button_sm Button_text" size="sm">
                <span className={`icon-arrow-left2 ${classes['ChangelogPost_back-icon']}`} />
                Back to All
              </Back>
            </Flex>
          ) : (
            <Flex
              align="end"
              className={classes['ChangelogPage_list-date']}
              dateTime={createdAt}
              gap="xs"
              layout="col"
              tag="time"
            >
              <DateLine icon={changelogIcon} time={createdAt} {...changelogFormat} />
              {!!isList && (
                <Flex
                  align="center"
                  className={`${classes.ChangelogPost_type} ${classes['ChangelogPage_list-type']}`}
                  gap="0"
                >
                  {!!typeIconClasses[type] && (
                    <span
                      className={`${typeIconClasses[type]} ${classes.ChangelogIcon} ${classes['ChangelogPage_list-icon']}`}
                    />
                  )}
                  {type}
                </Flex>
              )}
            </Flex>
          )}
          {isLoading ? (
            <div className={`${classes.ChangelogPost} ${classes['ChangelogPage_list-post']}`}>
              <Spinner className={classes.ChangelogSpinner} size="xxl" />
            </div>
          ) : (
            <div className={`${classes.ChangelogPost} ${classes['ChangelogPage_list-post']}`}>
              {!!type && !isList && (
                <div className={classes.ChangelogPost_type}>
                  {!!typeIconClasses[type] && <span className={`${typeIconClasses[type]} ${classes.ChangelogIcon}`} />}
                  {type}
                </div>
              )}
              <header className={classes.ChangelogPost_header}>
                {!isList ? (
                  <h1 className={classes.ChangelogPost_title}>{title}</h1>
                ) : (
                  <Flex gap="0" layout="col">
                    <Link
                      to={{
                        pathname: `/changelog/${slug}`,
                        state: { fromChangelogList: true } as LocationState,
                      }}
                    >
                      <h1 className={classes.ChangelogPost_title}>{title}</h1>
                    </Link>
                    {!!showAuthor && !!author?.name && (
                      <address className={classes['ChangelogPost-author']}>by {author.name}</address>
                    )}
                  </Flex>
                )}
                {!!createdAt && !isList && (
                  <DateLine
                    className={classes.ChangelogPost_date}
                    icon={changelogIcon}
                    suffix={showAuthor && author?.name ? `by ${author.name}` : undefined}
                    time={createdAt}
                    {...changelogFormat}
                  />
                )}
              </header>
              <section key={slug} className={classes.ChangelogPost_text}>
                <RDMD opts={{ mdx: true }}>{changelog?.content.body}</RDMD>
              </section>
            </div>
          )}
        </article>
      </div>
    </main>
  );
}

/**
 * Renders changelog posts in "view" mode.
 */
export default function ChangelogPost(props: ChangelogPostProps) {
  const { initialChangelog = null, isList = false } = props;
  const { slug } = useParams<SuperHubRouteParams>();

  return isList ? (
    <Content {...props} />
  ) : (
    <InitializeSuperHubDocument document={initialChangelog}>
      <ConnectSuperHubDocumentToApi slug={slug}>
        <Content {...props} />
      </ConnectSuperHubDocumentToApi>
    </InitializeSuperHubDocument>
  );
}
