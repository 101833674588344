import React from 'react';
import { useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { useProjectStore, useSuperHubStore } from '@core/store';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Segment, { SegmentItem } from '@ui/Segment';

import styles from './index.module.scss';

const navItems = [
  {
    icon: 'book-open',
    label: 'Guides',
    section: 'docs',
  },
  {
    icon: 'recipes',
    label: 'Recipes',
    section: 'recipes',
  },
  {
    icon: 'code',
    label: 'API Reference',
    section: 'reference',
  },
  {
    icon: 'megaphone',
    label: 'Changelog',
    section: 'changelog',
  },
  {
    icon: 'file',
    label: 'Custom Pages',
    section: 'page',
  },
] as const;

interface SectionNavProps {
  className?: string;
}

/**
 * Renders links to navigate between sections while in edit mode.
 */
function SectionNav({ className }: SectionNavProps) {
  const bem = useClassy(styles, 'SectionNav');
  const projectNavLinks = useProjectStore(s => s.data.appearance.navigation.links);
  const isSuperHubDevelopment = useSuperHubStore(s => s.isSuperHubDevelopment);

  const { section: routeSection } = useParams<SuperHubRouteParams>();

  const selectedIndex = navItems.findIndex(({ section }) => section === routeSection);

  return (
    <Flex align="center" className={bem('&', className)} justify="center" tag="nav">
      <Segment circular ghost kind="primary" selectedIndex={selectedIndex} size="sm">
        {navItems
          // TODO(2024/8/28): Remove this filter to expose reference section outside of our internal dev flag.
          .filter(item => (isSuperHubDevelopment ? true : item.section !== 'reference'))
          .map(({ icon, label, section }) => {
            const navlink = projectNavLinks[section === 'docs' ? 'guides' : section];
            return (
              <SegmentItem key={section} to={`/update/${section}`}>
                <Icon name={icon} />
                {navlink?.alias || label}
              </SegmentItem>
            );
          })}
      </Segment>
    </Flex>
  );
}

export default SectionNav;
